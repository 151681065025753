import { useConnectMicrosoftCalendarMutation } from '@/api/piloteer';
import { Spinner } from '@/ui/@components/feedback/loading/spinner';
import { APP_ROUTES } from '@/ui/application-routes';
import { useToast } from '@/ui/hooks/use-toast';
import { FunctionComponent, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const MicrosoftCalendarOAuthCallback: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [connectMicrosoftCalendar] = useConnectMicrosoftCalendarMutation();

  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      connectMicrosoftCalendar({
        authorizationCode: code,
      })
        .unwrap()
        .then(() => {
          toast({
            title: 'Microsoft Calendar connected',
            description:
              'Now you can invite Piloteer to your scheduled meetings',
            variant: 'success',
          });
          navigate(`${APP_ROUTES.MEETINGS.MAIN}?openCalendarModal=true`);
        })
        .catch((error) => {
          console.error('Failed to connect Microsoft Calendar:', error);
          toast({
            title: 'Failed to connect Microsoft Calendar',
            description: 'Please try again or contact support',
            variant: 'destructive',
          });
          navigate(APP_ROUTES.SETTINGS.INTEGRATIONS);
        });
    } else {
      navigate(APP_ROUTES.SETTINGS.INTEGRATIONS);
    }
  }, [location, connectMicrosoftCalendar, searchParams, toast, navigate]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>
        <Spinner />
        <p className='mt-4 text-xl font-medium'>
          Successfully connected to Microsoft Calendar
        </p>
        <p className='mt-2 text-muted-foreground'>
          Redirecting you back to settings...
        </p>
      </div>
    </div>
  );
};

export default MicrosoftCalendarOAuthCallback;
