import * as Sentry from '@sentry/react';
import { loadStripe } from '@stripe/stripe-js';
import '@vidstack/react/player/styles/base.css';
import '@vidstack/react/player/styles/default/layouts/audio.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import '@vidstack/react/player/styles/default/theme.css';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import './index.css';
import { store } from './store/redux';
import { Toaster } from './ui/@components/primitives/toaster';
import { ThemeProvider } from './ui/@components/theme/theme-provider';
import ApplicationRoutes from './ui/application-routes';

export const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLIC_KEY as string
);

const sentryDsn = import.meta.env.VITE_SENTRY_DSN as string;
const posthogApiKey = import.meta.env.VITE_POSTHOG_API_KEY as string;
const posthogOptions = {
  api_host: import.meta.env.VITE_POSTHOG_API_HOST,
  capture_pageview: true,
  capture_pageleave: true,
  autocapture: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
      email: false,
      credit_card: true,
    },
  },
  property_blacklist: [],
  mask_all_text: false,
  mask_all_element_attributes: false,
  advanced_disable_decide: false,
  persistence: 'localStorage+cookie' as const,
  // Capture more device and browser info
  capture_performance: true,
  enable_recording_console: true,
  record_dead_clicks: true,
  record_network: true,
};

// Initialize PostHog
if (posthogApiKey) {
  posthog.init(posthogApiKey, posthogOptions);

  // Set default properties for all events
  posthog.register({
    app_version: import.meta.env.VITE_APP_VERSION || '1.0.0',
    environment: import.meta.env.VITE_TENANT,
    initial_referrer: document.referrer,
    initial_utm_source: new URLSearchParams(window.location.search).get(
      'utm_source'
    ),
    device_type: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
      navigator.userAgent
    )
      ? 'mobile'
      : 'desktop',
  });
}

// Listen for auth state changes to identify users in PostHog
store.subscribe(() => {
  const state = store.getState();
  const user = state.auth.session?.user;

  if (user?.id && user?.email) {
    posthog.identify(user.id, {
      email: user.email,
      name: user.user_metadata?.name,
    });
  }
});

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <ThemeProvider>
            <Toaster />
            <BrowserRouter>
              <ApplicationRoutes />
            </BrowserRouter>
          </ThemeProvider>
        </ReduxProvider>
      </HelmetProvider>
    </PostHogProvider>
  </React.StrictMode>
);
