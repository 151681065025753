import { useConnectGoogleCalendarMutation } from '@/api/piloteer';
import { Spinner } from '@/ui/@components/feedback/loading/spinner';
import { APP_ROUTES } from '@/ui/application-routes';
import { useToast } from '@/ui/hooks/use-toast';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const GoogleCalendarOAuthCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const hasConnected = useRef(false);

  const [connectGoogleCalendar] = useConnectGoogleCalendarMutation();

  const handleConnectGoogleCalendar = useCallback(
    async (authorizationCode: string) => {
      if (hasConnected.current) return;
      hasConnected.current = true;

      try {
        await connectGoogleCalendar({
          authorizationCode,
        }).unwrap();

        toast({
          title: 'Google Calendar connected',
          description: 'Now you can invite Piloteer to your scheduled meetings',
          variant: 'success',
        });

        navigate(APP_ROUTES.MEETINGS.MAIN, {
          state: { openCalendarModal: true },
        });
      } catch (error) {
        console.error('Failed to connect Google Calendar:', error);

        toast({
          title: 'Failed to connect Google Calendar',
          description: 'Please try again or contact support',
          variant: 'destructive',
        });

        navigate(APP_ROUTES.SETTINGS.INTEGRATIONS, {
          state: { googleCalendarConnectError: true },
        });
      }
    },
    [connectGoogleCalendar, navigate, toast]
  );

  useEffect(() => {
    const authorizationCode = searchParams.get('code');

    if (authorizationCode) {
      handleConnectGoogleCalendar(authorizationCode);
    } else {
      navigate(APP_ROUTES.SETTINGS.INTEGRATIONS);
    }
  }, [searchParams, handleConnectGoogleCalendar, navigate]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>
        <Spinner />
        <p className='mt-4 text-xl font-medium'>
          Connecting your Google Calendar
        </p>
      </div>
    </div>
  );
};

export default GoogleCalendarOAuthCallback;
