import { CalendarEvent, CalendarEventsResponse } from '@/types/calendar';
import {
  GoogleIntegration,
  MicrosoftIntegration,
  ZoomIntegration,
} from '@/types/integration';
import {
  Meeting,
  MeetingDimensionalReportsAggregated,
  MeetingLeadershipResonanceAggregated,
  RecentMeeting,
} from '@/types/meeting';
import { Objective } from '@/types/objective';
import { CheckoutSession } from '@/types/transaction';
import { Transcript } from '@/types/transcript';
import { BillingPeriod, User } from '@/types/user';
import { createApi } from '@reduxjs/toolkit/query/react';
import snakeCaseKeys from 'snakecase-keys';

import { baseQueryWithIntercept } from './base';

export const piloteerApi = createApi({
  reducerPath: 'piloteerApi',
  baseQuery: baseQueryWithIntercept,
  tagTypes: ['User', 'CalendarEvents'],
  endpoints: (builder) => ({
    check: builder.query<void, void>({
      query: () => 'check',
    }),

    // User endpoints
    getUser: builder.query<User, void>({
      query: () => 'users',
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<User, Partial<User>>({
      query: (user) => ({
        url: 'users',
        method: 'PATCH',
        body: snakeCaseKeys(user, { deep: true }),
      }),
      invalidatesTags: ['User'],
    }),
    updateProfilePicture: builder.mutation<User, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: '/users/profile-picture',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['User'],
    }),

    // Meeting endpoints
    getAllMeetings: builder.query<Meeting[], void>({
      query: () => 'meetings',
    }),
    getRecentMeetings: builder.query<RecentMeeting[], void>({
      query: () => 'meetings/recent',
    }),
    getMeetingByUuid: builder.query<Meeting, string>({
      query: (uuid) => `meetings/${uuid}`,
    }),
    getMeetingTranscript: builder.query<Transcript, string>({
      query: (uuid) => `meetings/${uuid}/transcript`,
    }),

    invite: builder.mutation<Meeting, string>({
      query: (meetingLink) => ({
        url: 'meetings/invite',
        method: 'POST',
        body: { meeting_url: meetingLink },
      }),
    }),
    getLeadershipResonancesAggregated: builder.query<
      MeetingLeadershipResonanceAggregated,
      void
    >({
      query: () => `meetings/leadership-resonances/aggregate`,
    }),
    getDimensionalReportsAggregated: builder.query<
      MeetingDimensionalReportsAggregated,
      void
    >({
      query: () => `meetings/dimensional-reports/aggregate`,
    }),

    // Objective endpoints
    getLatestObjectives: builder.query<Objective[], void>({
      query: () => 'objectives/latest',
    }),

    // Subscription endpoints
    createSubscriptionCheckout: builder.mutation<
      CheckoutSession,
      { plan: BillingPeriod }
    >({
      query: ({ plan }) => ({
        url: 'transactions/subscriptions/create-checkout-session',
        method: 'POST',
        body: { plan },
      }),
    }),
    createCreditPurchaseCheckout: builder.mutation<
      CheckoutSession,
      { quantity: number }
    >({
      query: ({ quantity }) => ({
        url: 'transactions/subscriptions/create-credit-purchase-session',
        method: 'POST',
        body: { quantity },
      }),
    }),

    // Integration endpoints
    connectZoomAccount: builder.mutation<void, ZoomIntegration>({
      query: (zoomIntegration) => ({
        url: 'integrations/zoom/connect',
        method: 'POST',
        body: snakeCaseKeys(zoomIntegration, { deep: true }),
      }),
    }),

    disconnectZoomAccount: builder.mutation<void, void>({
      query: () => ({
        url: 'integrations/zoom/disconnect/',
        method: 'POST',
      }),
    }),

    connectGoogleCalendar: builder.mutation<void, GoogleIntegration>({
      query: (googleIntegration) => ({
        url: 'integrations/calendar/google/connect',
        method: 'POST',
        body: snakeCaseKeys(googleIntegration, { deep: true }),
      }),
    }),

    disconnectGoogleCalendar: builder.mutation<void, void>({
      query: () => ({
        url: 'integrations/calendar/google/disconnect',
        method: 'POST',
      }),
    }),

    connectMicrosoftCalendar: builder.mutation<void, MicrosoftIntegration>({
      query: (microsoftIntegration) => ({
        url: 'integrations/calendar/microsoft/connect',
        method: 'POST',
        body: snakeCaseKeys(microsoftIntegration, { deep: true }),
      }),
    }),

    disconnectMicrosoftCalendar: builder.mutation<void, void>({
      query: () => ({
        url: 'integrations/calendar/microsoft/disconnect',
        method: 'POST',
      }),
    }),

    // Calendar event endpoints
    getCalendarEvents: builder.query<CalendarEventsResponse, { days: number }>({
      query: ({ days }) => `calendars/events?days=${days}`,
      providesTags: ['CalendarEvents'],
    }),
    getScheduledCalendarEvents: builder.query<CalendarEventsResponse, void>({
      query: () => 'calendars/events/scheduled',
      providesTags: ['CalendarEvents'],
    }),
    scheduleCalendarEvent: builder.mutation<CalendarEvent, string>({
      query: (recallCalendarEventId) => ({
        url: `calendars/events/${recallCalendarEventId}/schedule`,
        method: 'POST',
      }),
      invalidatesTags: ['CalendarEvents'],
    }),
    unscheduleCalendarEvent: builder.mutation<{ status: string }, string>({
      query: (recallCalendarEventId) => ({
        url: `calendars/events/${recallCalendarEventId}/unschedule`,
        method: 'POST',
      }),
      invalidatesTags: ['CalendarEvents'],
    }),
  }),
});
